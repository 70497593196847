import React from "react"
import { navigate } from "gatsby"
import SEO from "../components/SEO"
import { Pagination } from "../components/ui"
import Footer from "../components/footer"
import "../styles/blog.less"
import { Content } from "./blog"
import { SolutionTopBanner, BlogCard, BlogCategory } from "../components/block"
const loadText = require("src/utils").loadText
const static_data = loadText("press")
// const Content = ({ blogs, categories, category }) => {
//   return (
//     <div
//       className="blog-content block-middle"
//       style={categories ? { maxWidth: "1240px" } : {}}
//     >
//       <div className="blog-content-wrapper page-middle">
//         {blogs.map(({ author, title, excerpt, date, slug, thumbnail }) => (
//           <BlogCard
//             author={author}
//             thumbnail={
//               thumbnail
//                 ? thumbnail.localFile
//                   ? thumbnail.localFile.publicURL
//                   : ""
//                 : ""
//             }
//             title={title}
//             excerpt={excerpt}
//             date={date}
//             id={slug}
//           />
//         ))}
//       </div>
//       <div style={{ marginTop: "56px" }}>
//         {categories && (
//           <BlogCategory categories={categories} active={category} />
//         )}
//       </div>
//     </div>
//   )
// }

const Press = ({ pageContext }) => {
  const {
    data,
    pageNum,
    pageTotal,
    prefixSlug,
    pageSize,
    categories,
    noIndex,
    category,
    prefixName,
  } = pageContext
  const _title = static_data.topBanner.title
  const title = prefixName ? `${_title}: ${prefixName}` : _title
  return (
    <div className="blog">
      <SEO
        {...static_data.seo}
        noIndex={noIndex}
        title={static_data.seo.title.replace(
          "{}",
          prefixName ? `| ${prefixName} ` : ""
        )}
        description={`${title}- ${static_data.seo.description}`}
      >
        {pageNum !== 1 && (
          <link
            rel="prev"
            href={`${process.env.GATSBY_HOST}${prefixSlug}/${
              pageNum > 2 ? `${pageNum - 1}/` : ""
            }`}
          />
        )}
        {pageNum !== pageSize && (
          <link
            rel="next"
            href={`${process.env.GATSBY_HOST}${prefixSlug}/${pageNum + 1}`}
          />
        )}
      </SEO>
      <SolutionTopBanner
        {...static_data.topBanner}
        bgPic={"/blog/topBanner-bg.svg"}
        height={520}
        pic={{
          src: "/blog/topBanner-pic.png",
          style: {
            bottom: 0,
            position: "absolute",
            right: "160px",
          },
        }}
        bannerPic={{
          src: `/blog/topBanner-bg-m.svg`,
          style: {
            backgroundPosition: "top center",
          },
        }}
        bgColor="linear-gradient(4deg,rgba(194,234,255,1) 0%,rgba(239,250,255,1) 100%)"
      />
      <Content
        blogs={data}
        type="press"
        categories={categories}
        category={category}
      />
      {pageTotal > pageSize && (
        <div className="blog-pagination">
          <Pagination
            current={pageNum}
            total={pageTotal}
            onChange={page => {
              navigate(`${prefixSlug}/${page > 1 ? `${page}/` : ""}`)
            }}
            // onChange={page => {
            //   navigate(`/press/${page}`)
            // }}
          />
        </div>
      )}
      {/* <BottomBanner /> */}
      <Footer />
    </div>
  )
}

export default Press
